import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { useEffect, useState } from 'react';
import { useAppState } from '../context/zustand';




export default function MyAlert(props) {
    const [open, setOpen] = useState();

    const alert = useAppState(state => state.alert)
    const clearAlert = useAppState(state => state.clearAlert)


    useEffect(() => {
        if (alert) {
            setOpen(true);
        } else {
            
            clearAlert()
        }
        // eslint-disable-next-line
    }, [alert])

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} variant="filled" severity={alert?.severity}>
                {alert?.message}
            </Alert>
        </Snackbar>
    )
}