import { Grid, makeStyles, Paper } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import EpisodeReleaseModul from "../components/graphs/EpisodesReleaseModul";
import EpisodesTotal from "../components/graphs/EpisodesTotals";
import PerEpisodeModul from "../components/graphs/PerEpisodeModul";
import PodcastModul from "../components/graphs/PodcastModul";
import { Copyright } from "../components/Copyright";
import { usePlatformCoef } from "../hooks/usePlatformCoef";
import { useAppState } from "../context/zustand";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
  },
  fixedHeight: {
    minHeight: 600,
  },
}));

function Graph({id, children}){
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const scopes = useAppState((s) => s.scopes[s.podcast?.name]);
  const isAdmin = useAppState((s) => s.isAdmin);

  return (
    <>
    {
      (scopes?.find(s => id === s) || isAdmin) &&
      <Grid item xs={12} md={12} lg={12}>
        <Paper className={fixedHeightPaper}>
          {children}
        </Paper>
      </Grid>
    }
    </>  
  )
}

export function PodcastScreen() {
  const platformKoef = usePlatformCoef();

  return (
    <Grid container spacing={3}>
      <Graph id="1">
        <EpisodeReleaseModul coef={platformKoef} />
      </Graph>
      <Graph id="2">
        <PerEpisodeModul coef={platformKoef} isCumulative={false} />
      </Graph>
      <Graph id="3">
        <PerEpisodeModul coef={platformKoef} isCumulative={true} />
      </Graph>
      <Graph id="4">
        <PodcastModul coef={platformKoef} />
      </Graph>
      <Graph id="5">
        <EpisodesTotal coef={platformKoef} />
      </Graph>
      <Grid item xs={12} md={12} lg={12}>
        <Copyright />
      </Grid>
    </Grid>
  );
}
