import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import App from './App';
import reportWebVitals from './reportWebVitals';

const queryClient = new QueryClient()

// const theme = createMuiTheme({
//   palette: {
//     // primary: {
//     //   // Purple and green play nicely together.
//     //   main: purple[500],
//     // },
//     // secondary: {
//     //   // This is green.A700 as hex.
//     //   main: '#11cb5f',
//     // },
//     text:{
//       primary:
//     }
//     background:{
//       default:'#303030',
//       paper:'#424242'
//     }
//   },
// });

// const darkTheme = createMuiTheme({
//   palette: {
//     type: 'dark',
//   },
// });

ReactDOM.render(
  <React.StrictMode>
    {/* <ThemeProvider> */}
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
    {/* </ThemeProvider> */}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
