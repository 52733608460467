import create from 'zustand'

function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

const initState = {
    isAuth: localStorage.getItem('isAuth') === 'true' || false,
    access: localStorage.getItem('access') || "",
    refresh: localStorage.getItem('refresh') || "",
    alert: false,
    isAdmin: localStorage.getItem('isAdmin') === 'true' || false,
    username: localStorage.getItem('username') || "",
    podcast: {},
    scopes: JSON.parse(localStorage.getItem("scopes"))
}

export const useAppState = create((set) => ({
    ...initState,
    setAlert: ({severity, message}) => set((state) => ({ ...state, alert: {severity, message} })),
    clearAlert: () => set((state) => ({ ...state, alert: false })),
    login: ({access, refresh, admin, username}) => set(
        (state) => {
            console.log("Logging in");

            let scopes = parseJwt(access)?.scopes
            localStorage.setItem('access', access);
            localStorage.setItem('refresh', refresh);
            localStorage.setItem('isAuth', true);
            localStorage.setItem('isAdmin', admin);
            localStorage.setItem('username', username);
            localStorage.setItem('scopes', JSON.stringify(scopes))
            
            return {
                ...state,
                isAuth: true,
                isAdmin: admin,
                access: access,
                refresh: refresh,
                username: username,
                scopes: scopes
              }
        }
    ),
    logout: () => set((state) => {
            console.log("Logging out");
            localStorage.clear();
            console.log("Clearing storage");
            return { ...initState, isAuth: false, alert: state.alert }
        }),
    setPodcast: (podcast) => set((state) => ({...state, podcast})),
}))