import axios from 'axios';
import {useQuery} from "react-query"
import { useAppState } from '../context/zustand';

export const myAxios = axios.create({
    timeout: 8000,
    // backend IP
    baseURL: (window.env && window.env.API_URL) || 'http://localhost:8000'
});

const responseMessages = {
    expiredCredentials: "Credentials expired! Please log in again!",
    backendError: "Unexpected error! Please contact our support@buttonsystems.eu",
    invalidCredentials: "No active account found with the given credentials!"
}

// Add a request interceptor
myAxios.interceptors.request.use(
    (config) => {
        let token = localStorage.getItem('access');
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + (token || "");
        }
        config.headers['Content-Type'] = (config.headers['Content-Type'] || 'application/json');
        return config
    },
    error => {
        console.log('Request error')
        Promise.reject(error)
    });

//Add a response interceptor
myAxios.interceptors.response.use(
    (response) => {
        return Promise.resolve(response);
    }, async (error) => {
        const originalRequest = error.config;
        console.log(`Error: ${error}`)
        const {getState} = useAppState

        if (!error.response) {
            console.log('Error: Network Error');
            getState().setAlert({severity:"error", message:responseMessages.backendError})
            return Promise.reject('Network Error');
        }

        if (error.response.status === 400 && originalRequest.url === '/api/token/') {
            return Promise.reject(error.response);
        }

        if (error.response.status === 400) {
            getState().setAlert({severity:"error", message:responseMessages.invalidCredentials})
            return Promise.reject("Invalid refresh");
        }

        if (error.response.status === 401 && originalRequest.url === '/api/token/refresh/') {
            getState().setAlert({severity:"error", message:responseMessages.backendError})
            getState().logout()
            return Promise.reject("Invalid refresh");
        }

        if (error.response.status === 406 && originalRequest.url === '/api/upload/') {
            return Promise.reject(error.response);
        }

        if (error.response.status === 401 && !originalRequest._retry) {
            console.log("Refreshing token")
            originalRequest._retry = true;
            const refreshToken = localStorage.getItem('refresh')
            if(!refreshToken){
                getState().setAlert({severity:"error", message:responseMessages.invalidCredentials})
                return Promise.reject("Invalid credentials")
            }
            return axios.post(`${originalRequest.baseURL}/api/token/refresh/`, { "refresh": refreshToken })
                .then(res => {
                    if (res.status === 200) {
                        console.log("Token refreshed!")
                        localStorage.setItem('access', res.data.access);
                        axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.access}`;
                        const conf = {
                            ...originalRequest,
                            headers: {
                                'Authorization': "Bearer " + res.data.access
                            }
                        }
                        return axios(conf);
                    }
                })
                .catch(err => {

                    if(err.response.status === 401){
                        console.log("Refresh token problem");
                        getState().logout()
                        getState().setAlert({severity:"error", message:responseMessages.expiredCredentials})
                        return Promise.reject("Refresh token problem")
                    }
                    else{
                        return Promise.reject(err.response) //default error above, thrown for the first time
                    }
                   
                })
        }

        getState().setAlert({severity:"error", message:responseMessages.backendError})
        return Promise.reject("Request error");
    });

export function useAxios(
    key,
    axiosParams,
    reactQueryProps = {},
    onSuccess = (res) => res,
    onError = (err) => err,
    onDone = () => {}
) {
    const props = useQuery(
        key,
        () =>
        myAxios(axiosParams)
                .then((res) => {
                    onSuccess(res)
                    onDone()
                    return Promise.resolve(res)
                })
                .catch((err) => {
                    onError(err)
                    onDone()
                    return Promise.reject(err)
                }),

        {
            enabled: false,
            retry: false,
            cacheTime: 2*1000,
            staleTime: 2*1000,
            ...reactQueryProps,
        }
    )

    return props
}
    


