import "./App.css";
import SignIn from "./pages/SignIn";
import { useAppState } from "./context/zustand";
import MyAlert from "./utils/MyAlert";
import { CssBaseline } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from "./components/Header";
import { PodcastScreen } from "./pages/PodcastScreen";

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginTop: theme.spacing(6),
  },
}));

function App() {
  const isAuth = useAppState((s) => s.isAuth);
  const classes = useStyles();

  return (
    <div className="App" style={{ paddingRight: `1px` }}>
      {isAuth ? (
        <>
          <Router>
            <CssBaseline />
            <Container className={classes.container}>
              <Switch>
                <Route path="/:podcast">
                  <Header />
                  <PodcastScreen />
                </Route>
                <Route path="/">
                  <Header />
                  <Typography>No podcast selected</Typography>
                </Route>
              </Switch>
            </Container>
          </Router>
        </>
      ) : (
        <SignIn />
      )}
      <MyAlert />
    </div>
  );
}

export default App;
