import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import React, { useEffect, useState } from 'react';


export default function CheckboxList(props) {

    const [values, setValues] = useState(props.values);

    const handleChange = (values) => {
        setValues(values);
        props.handleChange(values);
    }

    useEffect(() => {
        setValues(props.values);
    }, [props.values])

    return (
        <Box display="flex" flexDirection="row" className={props.className}>
            {Object.entries(values).map(
                ([key, value], index) => {
                    return (<FormControlLabel
                        key={index}
                        control={
                            <Checkbox
                                color="primary"
                                checked={(value > 0)}
                                onChange={(event, prevValue) => {
                                    if (!prevValue) {
                                        handleChange({ ...values, [key]: 0 })
                                    }
                                    else {
                                        handleChange({ ...values, [key]: props.defaultValues[key] });
                                    }

                                }}
                                name="checkedA" />
                        }
                        label={key}
                    />
                    )
                }
            )}
        </Box>

    )
}
