import { Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import Popover from '@material-ui/core/Popover';
import Slider from '@material-ui/core/Slider';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TuneIcon from '@material-ui/icons/Tune';
import React, { useEffect } from 'react';
import { useAppState } from '../context/zustand';



const useStyles = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(1),
    },
    slider: {
        width: 120,
    },
    input: {
        marginLeft: 15,
        width: 42,
    },
    divider: {
        border: `0px solid`,
        borderTop: `2px solid`,
        borderColor: theme.palette.grey[200],
        width: "100%",
        marginTop: 10,
        marginBottom: 10
    }
}));

export default function KoefPopover(props) {
    const classes = useStyles();
    const isAdmin = useAppState(s => s.isAdmin)

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [values, setValues] = React.useState(props.values);

    useEffect(() => {
        setValues(props.values);
    }, [props.values])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleChange = (values) => {
        props.handleChange(values);
        setValues(values);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    if (!props.children && !isAdmin) {
        return <></>
    }

    return (
        <>
            <IconButton aria-describedby={id} onClick={handleClick}>
                <TuneIcon color="primary" />
            </IconButton>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box display="flex" flexDirection="column" p={3} justifyContent="center" >
                    <Typography variant="h5"
                        className={classes.typography}>Settings</Typography>
                    {isAdmin &&
                        <Box>


                            <hr className={classes.divider} />

                            <Typography
                                className={classes.typography} variant="h6">Coefficients</Typography>
                            {Object.keys(values).map((item, index) => {
                                return (
                                    <Box key={`box123${index}`} display="flex" flexDirection="row"
                                        alignItems="center"
                                    >
                                        <Typography
                                            className={classes.typography}>{item}</Typography>
                                        <Box flexGrow={1} display="flex" flexDirection="row"
                                            justifyContent="flex-end"
                                            alignItems="center">
                                            <Slider
                                                className={classes.slider}

                                                value={values[item]}
                                                min={0}
                                                step={0.1}
                                                max={2}
                                                marks={[{ value: 1 }]}
                                                onChange={(event, newValue) => {
                                                    handleChange({ ...values, [item]: newValue });
                                                }}

                                                aria-labelledby="input-slider"
                                            />
                                            <Input

                                                className={classes.input}
                                                value={values[item]}
                                                margin="dense"
                                                onChange={(event) => {
                                                    const obj = {};
                                                    obj[item] = event.target.value;
                                                    setValues({ ...values, ...obj });
                                                }}
                                                inputProps={{
                                                    step: 0.1,
                                                    min: 0,
                                                    max: 2,
                                                    type: 'number',
                                                    'aria-labelledby': 'input-slider',
                                                }}
                                            />
                                        </Box>

                                    </Box>

                                )
                            })}
                        </Box>
                    }
                    {props.children &&

                        <>
                            <hr className={classes.divider} />

                            {React.Children.map(props.children, (child, index) => (
                                <Box key={`BoxChild${index}`} display="flex" justifyContent="flex-start" flexDirection="row" p={1} alignItems="center">
                                    {React.cloneElement(child, { style: { ...child.props.style } })}
                                </Box>
                            ))}
                        </>
                    }
                </Box>

            </Popover>
        </>
    );
}