import { Box, Checkbox, FormControlLabel, Grid, IconButton, Typography } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useAxios } from "../../utils/request";
import { CustomTooltip, multiplyObjectsByKey, sumObjectItems, PLATFORMS } from "../../utils/utils";
import CheckboxList from "../CheckboxList";
import KoefPopover from "../KoefPopover";
import { useAppState } from "../../context/zustand";






const useStyles = makeStyles(() => ({
    root: {
        paddingInline: 30
    }
}));



export default function EpisodeReleaseModul(props) {
    const theme = useTheme()
    const classes = useStyles();
    const {podcast} = useParams();
    const [rawData, setRawData] = useState([]);
    const [episodes, setEpisodes] = useState([]);
    const [platformKoef, setPlatformKoef] = useState({})
    const [isBar, setIsBar] = useState(true);
    const [isLine, setIsLine] = useState(true);
    const selectedPodcast = useAppState(s => s.podcast)

    useEffect(() => {
        setPlatformKoef(props.coef)
    }, [props.coef])

    const getEpisodesRequest = useAxios(
        ["episodes", podcast],
        {
            method: 'get',
            url: `api/episodes/`,
            params:{
                ordering:'release_date',
                podcast__name: podcast
            }
        },
        {enabled: podcast !== 'undefined'},
        (res) => {
            setRawData(res.data.results);
            setEpisodes(processData(res.data.results));
        },
        (err) => {}
    )

    useEffect(() => {
        if (rawData) {
            let data = processData(rawData);
            setEpisodes(data)
        }
        // eslint-disable-next-line
    }, [platformKoef])

    const processData = (resData) => {

        return resData
            .filter(item => (sumObjectItems(item.release_week_quantity, {}) !== 0))
            .map(item => (
                {
                    id: item.id,
                    ...multiplyObjectsByKey(item.release_week_quantity, platformKoef),
                    release_date: item.release_date,
                    total: sumObjectItems(item.release_week_quantity, platformKoef),
                }))

    }

    const updateData = () => {
        getEpisodesRequest.refetch()
    }

    return (
        <>
            <Grid container spacing={3} className={classes.root}>
                <Grid item lg={12}>
                    <Box paddingY={5} display="flex" alignItems="center" justifyContent="space-between" flexDirection="row">

                        <Box display="flex" alignItems="center" justifyContent="space-between" flexDirection="row">
                            <Typography align="left" variant='h4'>{`First ${selectedPodcast?.release_week_length ?? ''} weeks after the release`}</Typography>

                            <Box width={50}>
                                {getEpisodesRequest.isLoading ?
                                    <CircularProgress size={20} /> :
                                    <IconButton onClick={
                                        () => { updateData() }
                                    }>
                                        <RefreshIcon color="primary" />
                                    </IconButton>}

                            </Box>
                        </Box>

                        <Box display="flex" alignItems="center" flexDirection="row">
                            <Box paddingRight={2}>
                                <KoefPopover values={platformKoef} handleChange={
                                    (value) => {
                                        setPlatformKoef(value)
                                    }
                                } >

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isBar}
                                                onChange={() => {
                                                    setIsBar(!isBar);
                                                }}
                                                color="primary"
                                            />
                                        }
                                        label="Platform bars"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isLine}
                                                onChange={() => {
                                                    setIsLine(!isLine);
                                                }}
                                                color="primary"
                                            />
                                        }
                                        label="Line"
                                    />

                                </KoefPopover>
                            </Box>
                            <CheckboxList
                                values={platformKoef}
                                defaultValues={props.coef}
                                handleChange={
                                    (value) => {
                                        setPlatformKoef(value);
                                    }
                                }
                            />

                        </Box>

                    </Box>

                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <ResponsiveContainer width="100%" height={500}>
                        <ComposedChart
                            data={episodes}
                            margin={{
                                top: 0,
                                right: 0,
                                left: 0,
                                bottom: 40,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="id" textAnchor="end" angle={-45} interval={parseInt(episodes.length / 20)} />
                            <YAxis />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend verticalAlign="top" />

                            {isBar && <Bar dataKey="spotify" stackId="a" fillOpacity={0.7} fill={PLATFORMS.SPOTIFY.color} />}
                            {isBar && <Bar dataKey="blubrry" stackId="a" fillOpacity={0.7} fill={PLATFORMS.BLUBRRY.color} />}
                            {isBar && <Bar dataKey="apple" stackId="a" fillOpacity={0.7} fill={PLATFORMS.APPLE.color} />}
                            {isLine && <Line type="monotone" dataKey="total" fill={theme.palette.primary.light} stroke={theme.palette.primary.light} strokeWidth={3} />}

                        </ComposedChart>
                    </ResponsiveContainer>
                </Grid>
            </Grid >

        </>
    )
}