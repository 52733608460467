import { Box, Checkbox, Chip, FormControlLabel, Grid, IconButton, Typography } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import HelpIcon from '@material-ui/icons/Help';
import Autocomplete from '@material-ui/lab/Autocomplete';
import chroma from "chroma-js";
import moment from 'moment';
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip as RechartTooltip, XAxis, YAxis } from 'recharts';
import { useAxios } from "../../utils/request";
import { CustomTooltip, getWeekInterval, HtmlTooltip, sumObjectItems, sumObjectsByKey } from "../../utils/utils";
import MyWeekPicker from '../Calendar';
import CheckboxList from "../CheckboxList";
import KoefPopover from "../KoefPopover";



const useStyles = makeStyles((theme) => ({
    root: {
        paddingInline: 30
    },
    wrapper: {
        position: 'relative',
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));



export default function PodcastModul(props) {


    const today = moment();
    const weekAgo = moment().subtract(21, 'd');
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [rawData, setRawData] = useState(false);
    const [episodes, setEpisodes] = useState([]);
    const [selectedEpisodes, setSelectedEpisodes] = useState([]);
    const [dateFrom, setDateFrom] = useState(`${weekAgo.isoWeekYear()},${weekAgo.isoWeek()}`);
    const [dateTo, setDateTo] = useState(`${today.isoWeekYear()},${today.isoWeek()}`);
    const [allEpisodes, setAllEpisodes] = useState(true);
    const [timeInterval, setTimeInterval] = useState(false);
    const [platformKoef, setPlatformKoef] = useState(props.coef);
    const {podcast} = useParams();

    const getAllEpisodesRequest = useAxios(
        ["allepisodes", podcast],
        {
            method: 'get',
            url: `/api/allepisodes/`,
            params: {
                episodes: (allEpisodes ? '' : `${selectedEpisodes.map(i => `${i?.id},`)}`),
                date_from: (timeInterval ? dateFrom : ''),
                date_to: (timeInterval ? dateTo : ''),
                // date_from: '9999,1',
                // date_to: '9999,2',
                podcast: podcast
            }
        },
        {enabled: podcast !== 'undefined' },
        (res) => {            
            setRawData(res.data);
            setData(processData(res.data));
        },
        (err) => {}
    )

    const getEpisodesRequest = useAxios(
        ["episodes", podcast],
        {
            method: 'get',
            url: '/api/episodes/',
            params: {
                ordering: 'release_date',
                podcast__name: podcast
            }
        },
        { enabled: podcast !== 'undefined' },
        (res) => {
            setEpisodes(res.data.results);
            setSelectedEpisodes([res.data.results[0]])
        },
        (err) => {}
    )

    useEffect(() => {
        setPlatformKoef(props.coef);
    }, [props.coef])


    const handleButtonClick = () => {
        updateData();
        if(!allEpisodes && selectedEpisodes.length === 0){
            setAllEpisodes(true);
        }
    }


    function getEpisodeColor(id, len) {
        return chroma.scale(['#fafa6e', '#2A4858']).mode('lch').colors(len)[id]
    }

    function processData(resData) {

        let data = JSON.parse(JSON.stringify(resData.results))
        let minDateValue = timeInterval ? dateFrom : resData.minDateValue
        let maxDateValue = timeInterval ? dateTo : resData.maxDateValue
        let dateInterval = getWeekInterval(minDateValue, maxDateValue);
        let newArray = []

        dateInterval.reduce((acc, curr, index) => {

            if (curr in data) {

                let episodes = {}
                Object.keys(data[curr]).forEach(episode => {
                    episodes[episode] = sumObjectItems(data[curr][episode], platformKoef)
                })

                let episode = sumObjectsByKey(acc, episodes);

                if (allEpisodes) {
                    newArray[index] = { 'date': curr, 'all_episodes': sumObjectItems(episode, {}) };
                }
                else {
                    newArray[index] = { 'date': curr, ...episode };

                }
                return episode
            }
            else {
                if (allEpisodes) {
                    newArray[index] = { 'date': curr, 'all_episodes': sumObjectItems(acc, {}) };
                }
                else {
                    newArray[index] = { 'date': curr, ...acc };

                }
                return acc
            }

        }, {})
        return newArray
    }

    const updateData = () => {
        getAllEpisodesRequest.refetch()
    }

    useEffect(() => {

        if (rawData) {
            let data = processData(rawData);
            setData(data);
        }
        // eslint-disable-next-line
    }, [platformKoef, rawData])

    useEffect(() => {
        if (selectedEpisodes.length === 0) {
            setAllEpisodes(true);
        }
    }, [selectedEpisodes])

    useEffect(() => {
        // updateData();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (getEpisodesRequest.isFetched) {
            setEpisodes(getEpisodesRequest.data?.data.results)
        }
    }, [getEpisodesRequest, podcast])

    useEffect(() => {
        setSelectedEpisodes([])
        updateData();
        // eslint-disable-next-line
    }, [podcast])

    return (
        <>
            <Grid container spacing={3} className={classes.root}>
                <Grid item lg={12}>
                    <Box paddingY={5} display="flex" justifyContent="space-between" alignItems="center" flexDirection="row" >
                        <Typography align="left" variant='h4'>Podcast downloads</Typography>
                        <Box display="flex" flexDirection="row" >
                            <Box paddingRight={2}>
                                <KoefPopover
                                    values={platformKoef}
                                    handleChange={
                                        (value) => {
                                            setPlatformKoef(value)
                                        }
                                    } />
                            </Box>
                            <CheckboxList
                                values={platformKoef}
                                defaultValues={props.coef}
                                handleChange={
                                    (value) => {
                                        setPlatformKoef(value);
                                    }
                                }
                            />
                        </Box>

                    </Box>

                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <ResponsiveContainer width="100%" height={500}>
                        <AreaChart
                            data={data}
                            margin={{
                                top: 0,
                                right: 0,
                                left: 0,
                                bottom: 0,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <RechartTooltip content={<CustomTooltip />} />
                            <Legend verticalAlign="top" />

                            {data[data.length - 1] && Object.keys(data[data.length - 1]).reduce((acc, item, index) => {
                                let len = Object.keys(data[data.length - 1]).length
                                if (item !== 'date') {
                                    acc.push(<Area type="monotone" key={`${item} ${index} `} activeDot={{ strokeWidth: 2 }} dot={{ strokeWidth: 5 }} dataKey={item} stackId="1" stroke={getEpisodeColor(index, len)} fill={getEpisodeColor(index, len)} />)
                                }
                                return acc

                            }, [])}
                        </AreaChart>
                    </ResponsiveContainer>

                </Grid>
                <Grid item xs={12} md={12} lg={9}>
                    <Box display="flex" alignItems="flex-start" flexDirection="column" >
                        <Grid
                            container
                            justify="flex-start"
                            alignItems="center"
                            spacing={3}
                            direction="row"
                        >
                            <Grid item xs={12} md={12} lg={3}>
                                <Box height={57} display="flex" alignItems="center" justifyContent="flex-start" flexDirection="row" >
                                    <FormControlLabel
                                        control={<Checkbox color="primary" checked={allEpisodes} onChange={() => setAllEpisodes(e => !e)} name="checkedA" />}
                                        label="All Episodes"
                                    />
                                </Box>

                            </Grid>
                            <Grid item xs={12} md={12} lg={9}>
                                {!allEpisodes &&
                                    <Box width="100%">
                                        <Autocomplete
                                            multiple
                                            id="combo-box-demo"
                                            options={episodes}
                                            value={selectedEpisodes}
                                            filterSelectedOptions
                                            onChange={(event, newValue) => {
                                                setSelectedEpisodes(newValue);
                                            }}
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => (
                                                    <Chip variant="outlined" label={(option ? option.id : '')} {...getTagProps({ index })} />
                                                ))
                                            }
                                            getOptionLabel={(option) => (option.name ? `${option.id}: ${option.name} ` : '')}
                                            style={{ width: "100%" }}
                                            renderInput={(params) => <TextField {...params} label="Episode" variant="outlined" />}
                                        />
                                    </Box>
                                }
                            </Grid>







                            <Grid item xs={12} md={12} lg={2}>
                                <Box height={57} display="flex" alignItems="center" justifyContent="flex-start" flexDirection="row">
                                    <FormControlLabel
                                        control={<Checkbox color="primary" checked={timeInterval} onChange={() => setTimeInterval(e => !e)} name="checkedA" />}
                                        label="Interval"
                                    />

                                    <HtmlTooltip title="Custom time interval between weeks (included)">
                                        <IconButton>
                                            <HelpIcon fontSize="small" />
                                        </IconButton>
                                    </HtmlTooltip>
                                </Box>
                            </Grid>


                            {timeInterval && [
                                <Grid item xs={12} md={12} lg={4}>
                                    <MyWeekPicker
                                        title="Week from"
                                        date={weekAgo.toDate()}
                                        handleDateChange={
                                            (date) => {
                                                setDateFrom(date)
                                            }
                                        } />
                                </Grid>,
                                <Grid item xs={12} md={12} lg={4}>
                                    <MyWeekPicker
                                        title="Week to"
                                        date={today.toDate()}
                                        handleDateChange={
                                            (date) => {
                                                setDateTo(date)
                                            }
                                        } />
                                </Grid>
                            ]}
                            <Grid item xs={12} md={12} lg={1}>
                                <Box display="flex" alignItems="center" justifyContent="flex-start" flexDirection="row">

                                    <div className={classes.wrapper}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={getAllEpisodesRequest.isLoading}
                                            onClick={handleButtonClick}
                                        >
                                            Refresh
        </Button>
                                        {getAllEpisodesRequest.isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                    </div>
                                </Box>
                            </Grid>
                        </Grid>

                    </Box>
                </Grid>
            </Grid >
        </>
    )
}