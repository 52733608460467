import { Box, Paper } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {blue, green, red} from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router';
import { myAxios } from '../../utils/request';
import {getPlatformIcon, HtmlTooltip} from '../../utils/utils';
import 'react-virtualized/styles.css'
import { VirtualizedTable } from '../VirtualizedTable';
import Avatar from "@material-ui/core/Avatar";


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    }
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const UploadButton = withStyles((theme) => ({
    root: {
        color: theme.palette.primary.contrastText,
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
}))(Button);

const useStyles = makeStyles((theme) => ({
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
}));


export default function UploadFileModal({ open, handleClose}) {

    const [uploadProgress, setUploadProgress] = useState(false);
    const reset = useRef(false);
    const [filesProgress, setFilesProgress] = useState([]);
    const [files, setFiles] = useState([]);
    const params = useParams();
    const classes = useStyles();

    const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '25px 20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    };

    const activeStyle = {
        borderColor: '#2196f3'
    };

    const acceptStyle = {
        borderColor: '#00e676'
    };

    const rejectStyle = {
        borderColor: '#ff1744'
    };


    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone();

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
        // eslint-disable-next-line
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    const postFile = (file, id, validate) => {

        var formData = new FormData();
        formData.append('file', file);
        formData.append('podcast', params?.podcast);
        formData.append('validate', validate);

        let conf = {
            url: '/api/upload/',
            headers: {
                'content-type': 'multipart/form-data'
            },
            onUploadProgress: progressEvent => {
                setFilesProgress(files => {
                    let tmp = [...files]
                    tmp[id].progress = (progressEvent.loaded / progressEvent.total) * 100
                    return tmp
                })
            }
        }
        return new Promise((resolve) => {
            setTimeout(() => {
                myAxios.post(
                    '/api/upload/',
                    formData,
                    conf
                ).then(res => {
                    resolve(res);
                })
                    .catch(err => {
                        resolve(err);
                    })
            }, 100)
        })
    }

    const postFiles = (files, id, validate) => {
        if(files.length === 0) return
        postFile(files[id], id, validate)
            .then(res => {
                setFilesProgress(files => {
                    let tmp = [...files]
                    tmp[id].status = res.status
                    tmp[id].message = (res.data ? res.data.message : 'No msg')
                    tmp[id].platform = res?.data?.platform

                    return tmp
                })
            })
            .finally(() => {
                id++;
                if (id < files.length && !reset.current) {
                    postFiles(files, id, validate);
                }
                else {
                    setUploadProgress(false);
                    reset.current = false;
                }
            })
    }

    useEffect(() => {
        setUploadProgress(true);
        setFilesProgress(files.map(i => ({ file: i, name: i.name, progress: 0, status: '' })));
        postFiles(files, 0, true);
        // eslint-disable-next-line
    }, [files])


    const handleSubmit = (validate) => {
        reset.current = false;
        setFilesProgress(files.map(i => ({  file: i, name: i.name, progress: 0, status: '' })));
        setUploadProgress(true);
        postFiles(files, 0, validate);
    }


    const handleReset = () => {
        reset.current = true;
        if (uploadProgress) {
            setFilesProgress(files => files.map(i => (i.progress === 0 ? { progress: 100, status: { code: 404, message: 'Upload aborted!' } } : i)));
            setUploadProgress(false);
        }
        else {
            setFiles([]);
        }
    }

    useEffect(() => {
        setFiles(acceptedFiles);
    }, [acceptedFiles])

    return (
        <Dialog fullWidth maxWidth={"lg"} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                {`Upload files for podcast: ${params?.podcast}`}
        </DialogTitle>
            <DialogContent dividers>
                <div>
                    <div  {...getRootProps({ style })}>
                        <input {...getInputProps()} />
                        <p>Drag 'n' drop files here, or click to select files</p>
                    </div>
                    <Box paddingY={1}>

                    <Paper style={{ height: 400, width: '100%' }}>
                         <VirtualizedTable
                            rowCount={filesProgress.length}
                            rowGetter={({ index }) => filesProgress[index]}
                            columns={[
                                {
                                    width: 450,
                                    label: 'FILENAME',
                                    dataKey: 'name',
                                    renderCell: ({name}) => {
                                        return (
                                            <HtmlTooltip title={name} placement="bottom">
                                                <Typography variant="p">{name}</Typography>
                                            </HtmlTooltip>
                                        )
                                    }
                                },
                                {
                                    width: 115,
                                    label: 'PLATFORM',
                                    dataKey: 'platform',
                                    style: {
                                        justifyContent: "center"
                                    },
                                    renderCell: ({platform}) => {
                                        return (
                                            platform
                                                ? <Avatar alt="Remy Sharp" src={getPlatformIcon(platform)} className={classes.small} />
                                                : ''
                                        )
                                    }
                                },
                                {
                                    width: 600,
                                    label: 'RESPONSE',
                                    dataKey: 'message',
                                    renderCell: ({message}) => {
                                        return (
                                            <HtmlTooltip title={message} placement="bottom">
                                                <Typography variant="p">{message}</Typography>
                                            </HtmlTooltip>
                                        )
                                    }
                                },
                                {
                                    width: 90,
                                    label: 'STATUS',
                                    dataKey: 'message',
                                    style: {
                                        justifyContent: "center"
                                    },
                                    renderCell: ({progress, status}) => {
                                        if (progress === -1){
                                            return ""
                                        }
                                        else if (0 <= progress && progress < 100){
                                            return <CircularProgress  size={20} />
                                        }
                                        else if (status === 200) {
                                            return <CheckCircleOutlineIcon style={{color: blue[500]}}/>
                                        }
                                        else if (status === 201) {
                                            return <CheckCircleOutlineIcon style={{color: green[500]}}/>
                                        }
                                        else if (status > 400) {
                                            return <ErrorOutlineIcon width={50} style={{ color: red[500] }} />
                                        }
                                        return <CircularProgress  size={20} />
                                    },

                                },
                            ]}
                        />
                        </Paper>
                       
                        {/* <VirtList
                            key={'rejectFiles'}
                            width={500}
                            height={files.length > 0 ? 300 : 0}
                            rowCount={files.length}
                            rowHeight={50}
                            rowRenderer={FileItem}
                        />
                        <VirtList
                            key={'uploadFiles'}
                            width={500}
                            height={fileRejections.length > 0 ? 300 : 0}
                            rowCount={fileRejections.length}
                            rowHeight={50}
                            rowRenderer={ItemRejectFile}
                        /> */}
                    </Box>


                </div>
            </DialogContent >
            <DialogActions>
                <Button variant="contained" onClick={handleReset} color="secondary">
                    {(uploadProgress ? 'Stop' : 'Reset')}
                </Button>
                <Button disabled={(files.length === 0 || uploadProgress)} variant="contained" onClick={() => handleSubmit(true)} color="primary">
                    Validate
                </Button>
                <UploadButton disabled={(files.length === 0 || uploadProgress)} variant="contained" onClick={() => handleSubmit(false)} color="primary">
                    Upload
                </UploadButton>
            </DialogActions>
        </Dialog >
    );
}
