import { Box, Divider, Paper, Tooltip, Typography, withStyles } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import AppleLogo from '../assets/Apple_logo_black.svg.png';
import SpotifyLogo from '../assets/Spotify_logo_without_text.svg.png';
import BlubrryLogo from '../assets/blubrry_3.png';
import HelpIcon from '@material-ui/icons/Help';
import { useAppState } from "../context/zustand";


export function multiplyObjectsByKey(obj, koef) {
    let tmp_obj = { ...obj }
    for (let k in koef) {
        if (tmp_obj.hasOwnProperty(k)) {
            tmp_obj[k] = koef[k] * obj[k];
        }
    }
    return tmp_obj
}

export function sumObjectsByKey(...objs) {
    return objs.reduce((a, b) => {
        for (let k in b) {
            if (b.hasOwnProperty(k))
                a[k] = (a[k] || 0) + b[k];
        }
        return a;
    }, {});
}

export function sumObjectItems(obj, koef) {
    return Object.keys(obj).reduce((acc, key) => {
        if (koef.hasOwnProperty(key)) {
            return (obj[key] * koef[key]) + acc
        }
        else {
            return obj[key] + acc
        }
    }, 0)
}

export function sumObjectItemsKoefOnly(obj, koef) {
    return Object.keys(obj).reduce((acc, key) => {
        if (koef.hasOwnProperty(key)) {
            return (obj[key] * koef[key]) + acc
        }
        else {
            return acc
        }
    }, 0)
}

export function getWeekInterval(from, to) {
    let intervalArray = []
    let tmp = moment(from, "GGGG,WW");
    let end = moment(to, "GGGG,WW");

    while (!tmp.isSameOrAfter(end)) {
        intervalArray.push(`${tmp.isoWeekYear()},${tmp.isoWeek()}`);
        tmp = tmp.add(1, 'w');

    }
    intervalArray.push(`${tmp.isoWeekYear()},${tmp.isoWeek()}`);
    return intervalArray
}

export function getWeek(date) {
    return moment(date).isoWeek()
}

export function getWeekRange(date, releaseWeekDay) {
    let from = moment(date)
        .startOf('isoWeek')
        .add(releaseWeekDay, "days")
        .format('YYYY-MM-DD')
    let to = moment(date)
        .endOf('isoWeek')
        .add(releaseWeekDay, "days")
        .format('YYYY-MM-DD')
    return { from, to }

}


export const CustomTooltip = ({ active, payload, label }) => {

    const selectedPodcast = useAppState(s => s.podcast)
    const releaseWeekDay = parseInt(selectedPodcast.release_week_day ?? 0)
    let extended_label = undefined;

    if (label){
        extended_label = label.match(/^(\d{4}),(\d{1,2})$/)

        if (extended_label){
            let year = extended_label[1]
            let week = extended_label[2]
            let weekDate = moment(`${year}-W${week}`, "YYYY-WW")
    
            extended_label = {
                'Week:': week,
                'Date from': weekDate.startOf('isoweek').add(releaseWeekDay, "days").format('YYYY-MM-DD'),
                'Date to:' : weekDate.endOf('isoweek').add(releaseWeekDay, "days").format('YYYY-MM-DD')
            }
        }
    }

    if (active && payload && payload.length) {
        let dateRange = getWeekRange(payload[0].payload.release_date, releaseWeekDay)
        return (
            <Paper >
                <Box width={250} paddingX={1}>
                    <Box paddingY={1}>
                        <Typography>{label}</Typography>
                        {extended_label && Object.entries(extended_label).map(
                            ([key, value]) => (<Typography key={key}>
                                {`${key} ${value}`}
                            </Typography>)
                        )}
                        {payload[0].payload.release_date &&
                        <>
                            <Typography >{`Release date: ${payload[0].payload.release_date}`}</Typography>
                            <Typography >{`Week: ${getWeek(payload[0].payload.release_date)}`}</Typography>
                            <Typography >{`Date from: ${dateRange.from}`}</Typography>
                            <Typography >{`Date to: ${dateRange.to}`}</Typography>
                        </>
                        }
                    </Box>

                    <Divider />
                    <Box paddingY={1}>
                        {payload.reduce((acc, item, index) => {
                            if (item.dataKey === "total") return acc;
                            acc.push(<Typography key={`CustomTooltip${index}`} display="block" style={{ color: item.fill, width: "100%" }}>{`${item.dataKey}: ${item.payload[item.dataKey]}`}</Typography>)
                            return acc
                        }, [])}
                    </Box>

                    {!!payload[0].payload.total &&
                        <>
                            <Divider />
                            <Box paddingY={1}>
                                <Typography >{`Total: ${payload[0].payload.total}`}</Typography>
                            </Box>
                        </>
                    }
                </Box>
            </Paper>

        );
    }

    return null;
};




export const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 500,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);


export const getPlatformIcon = (platform) => {
    if(platform === "spotify") return SpotifyLogo
    else if(platform === "apple") return AppleLogo
    else if(platform === "blubrry") return BlubrryLogo
    else{
        return HelpIcon
    }
}

export const PLATFORMS = {
    SPOTIFY: {
        color: 'green',
        icon: SpotifyLogo
    },
    BLUBRRY: {
        color: 'purple',
        icon: BlubrryLogo
    },
    APPLE: {
        color: 'grey',
        icon: AppleLogo
    }
}