import {
    AppBar,
    Avatar,
    Box,
    Button,
    Container, Drawer, FormControl, IconButton,

    List, ListItem,
    ListItemIcon,
    ListItemText, makeStyles, Menu, MenuItem, Select, Toolbar,

    withStyles
} from "@material-ui/core";
import BackupIcon from '@material-ui/icons/Backup';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from "@material-ui/icons/Menu";
import StorageIcon from '@material-ui/icons/Storage';
import PersonIcon from '@material-ui/icons/Person';
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { myAxios, useAxios } from "../utils/request";
import UploadFileModal from './modals/UploadModal';
import { useAppState } from "../context/zustand";



const MenuButton = withStyles((theme) => ({
    root: {
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
            boxShadow: 'none',
        },
        boxShadow: 'none',
        padding: 15
    },
}))(Button);



const useStyles = makeStyles((theme) => ({
    header: {
        "@media (max-width: 900px)": {
            paddingLeft: 0,
        },
    },
    menuButton: {
        size: "18px",
        marginLeft: "38px",
    },
    toolbar: {
        display: "flex",
        justifyContent: "space-between",
    },
    drawerContainer: {
        padding: "20px 0px",
    },
    podcastClass: {
        minWidth: 200,
        maxHeight: 40,
        textAlign: "left",
        color: "white",
        borderColor: "white"
    },
    menuItem: {
        minWidth: 200,
    }
}));

export default function Header() {
    const { header, menuButton, toolbar, drawerContainer, podcastClass, menuItem } = useStyles();

    const [openUploadModal, setOpenUploadModal] = useState(false);
    const isAdmin = useAppState(s => s.isAdmin)
    const username = useAppState(s => s.username)
    const { getState } = useAppState;

    const [allPodcasts, setAllPodcasts] = useState([])
    const [podcast, setPodcast] = useState(null);
    const history = useHistory();
    const params = useParams()
    const logout = useAppState(s => s.logout)

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    useAxios(
        ["podcasts"],
        {
            url: "/api/podcasts/",
            method: "GET"
        },
        { enabled: true },
        (res) => {
            const podcasts = res.data.results
            if(podcasts.length === 0){
                setPodcast(null)
            }
            setAllPodcasts(podcasts)
            let item = res.data.results.find(item => item.name === params?.podcast)
            if (item) {
                setPodcast(item)
            }
            else {
                setPodcast(res.data.results[0])
            }
        }
    )

    

    const [viewState, setViewState] = useState({
        mobileView: false,
        drawerOpen: false,
    });

    const { mobileView, drawerOpen } = viewState;

    const handleDrawerOpen = () =>
        setViewState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
        setViewState((prevState) => ({ ...prevState, drawerOpen: false }));

    useEffect(() => {
        if(podcast){
            history.push(`${podcast?.name}`);
            getState().setPodcast(podcast)
        }
        else{
            // history.push(``);
            // getState().setPodcast(null)
        }
        // eslint-disable-next-line
    }, [podcast])

    const handlePodcastSelect = (e) => {
        setPodcast(e.target.value);
    }

    const headersData = [
        {
            label: "Upload file",
            href: "/",
            onClick: () => {
                setOpenUploadModal(true);
            },
            icon: <BackupIcon />
        },
        {
            label: "Admin panel",
            href: "/",
            onClick: () => {
                window.open(`${myAxios.defaults.baseURL}/db-manager`, '_blank', 'noopener,noreferrer');
            },
            icon: <StorageIcon />
        },
        {
            label: "Logout",
            href: "/",
            onClick: () => {
                logout()
            },
            icon: <ExitToAppIcon />
        }
    ].filter((item, index) => (isAdmin || index > 1));

    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < 900
                ? setViewState((prevState) => ({ ...prevState, mobileView: true }))
                : setViewState((prevState) => ({ ...prevState, mobileView: false }));
        };

        setResponsiveness();

        window.addEventListener("resize", () => setResponsiveness());
    }, []);

    const displayDesktop = () => {
        return (

            <Toolbar className={toolbar} component={Container}>

                {mainLogo}
                <div>
                    {getMenuButtons().slice(0, -1)}
                    <MenuButton
                    variant="contained"
                    color="primary"
                    className={menuButton}
                    key={`MenuButton`}
                    startIcon={<PersonIcon/>}
                    onClick={handleClick}
                >
                    {username}
                    </MenuButton>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        >
                        <MenuItem onClick={() => {logout()}}>
                            Logout
                        </MenuItem>
                    </Menu>
                </div>
            </Toolbar>
        );
    };

    const displayMobile = () => {


        return (
            <Toolbar className={toolbar}>
                <IconButton
                    {...{
                        edge: "start",
                        color: "inherit",
                        "aria-label": "menu",
                        "aria-haspopup": "true",
                        onClick: handleDrawerOpen,
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <div>{mainLogo}</div>
            </Toolbar>
        );
    };

    const getDrawerChoices = () => {
        return headersData.map(({ label, icon, onClick }) => {
            return (
                <ListItem
                    button
                    key={`ListItem${label}`}
                    onClick={onClick}
                >
                    <ListItemIcon>
                        {icon}
                    </ListItemIcon>
                    <ListItemText primary={label} />
                </ListItem>
            );
        });
    };

    const mainLogo = (
        <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
            <Box paddingRight={2}>
                <Avatar alt="Remy Sharp" src={podcast?.artwork_url} />
            </Box>
            <FormControl className={podcastClass}
            >
                <Select
                    className={podcastClass}
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={podcast}
                    onChange={handlePodcastSelect}
                    label="Podcast"
                    renderValue={
                        (value) => (`${value?.name}: ${value.full_name}`)
                    }
                >
                    {allPodcasts.map(
                        (item) => {
                            return <MenuItem className={menuItem} key={`podcast${item.name}`} value={item}>{`${item?.name}: ${item.full_name}`}</MenuItem>
                        }
                    )}
                </Select>
            </FormControl>
        </Box>

    )


    const getMenuButtons = () => {
        return headersData.map(({ label, href, icon, onClick }) => {
            return (
                <MenuButton
                    variant="contained"
                    color="primary"
                    key={`MenuButton${label}`}
                    className={menuButton}
                    startIcon={icon}
                    onClick={onClick}
                >
                    {label}
                </MenuButton>
            );
        });
    };

    return (
        <header>
            <AppBar className={header}>
                {mobileView ? displayMobile() : displayDesktop()}
            </AppBar>
            <Drawer
                {...{
                    anchor: "left",
                    open: drawerOpen,
                    onClose: handleDrawerClose,
                }}
            >
                <div className={drawerContainer}>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <PersonIcon/>
                            </ListItemIcon>
                            <ListItemText primary={"admin"} />
                        </ListItem>
                        {getDrawerChoices()}
                    </List>

                </div>
            </Drawer>
            {
                openUploadModal &&
                <UploadFileModal
                    open={openUploadModal}
                    handleClose={() => {
                        setOpenUploadModal(false);
                    }}
                />
            }
        </header>
    );
}