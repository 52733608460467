import { makeStyles, TextField } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { useAppState } from "../context/zustand";


function getWeekDays(weekStart) {
    const days = [weekStart];
    for (let i = 1; i < 7; i += 1) {
        days.push(
            moment(weekStart)
                .add(i, 'days')
                .toDate()
        );
    }
    return days;
}

function getWeekRange(date, releaseWeekDay) {
    let from = moment(date)
        .startOf('isoWeek')
        .add(releaseWeekDay, "days")
        .toDate()
    let to = moment(date)
        .endOf('isoWeek')
        .add(releaseWeekDay, "days")
        .toDate();
    return { from, to }

}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        height: "100%",
        margin: theme.spacing(3, 0, 2),
    },
    dayPickerInput: {
        width: "100%",
        zIndex: 0
    }
}));

export default function MyWeekPicker(props) {
    const { title } = props
    const selectedPodcast = useAppState(s => s.podcast)
    const releaseWeekDay = parseInt(selectedPodcast.release_week_day ?? 0)
    const classes = useStyles();
    const [hoverRange, setHoverRange] = useState(undefined);
    const [selectedDays, setSelectedDays] = useState(getWeekDays(getWeekRange(props.date, releaseWeekDay).from));
    const theme = useTheme();
    

    const handleDayChange = date => {
        setSelectedDays(getWeekDays(getWeekRange(date, releaseWeekDay).from));
    };

    useEffect(() => {
        props.handleDateChange(`${moment(selectedDays[3]).isoWeekYear()},${moment(selectedDays[3]).isoWeek()}`);
    }, [selectedDays, props])

    const handleDayEnter = date => {
        setHoverRange(getWeekRange(date, releaseWeekDay));
    };

    const handleDayLeave = () => {
        setHoverRange(undefined);
    };

    const handleWeekClick = (weekNumber, days, e) => {
        setSelectedDays(days);
    };

    const daysAreSelected = selectedDays.length > 0;

    const modifiers = {
        hoverRange,
        selectedRange: daysAreSelected && {
            from: selectedDays[0],
            to: selectedDays[6],
        },
        hoverRangeStart: hoverRange && hoverRange.from,
        hoverRangeEnd: hoverRange && hoverRange.to,
        selectedRangeStart: daysAreSelected && selectedDays[0],
        selectedRangeEnd: daysAreSelected && selectedDays[6],
        today: new Date()
    };

    const modifiersStyles = {
        today: {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.dark,
            borderRadius: 0
        },
        hoverRange: {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.contrastText,
            borderRadius: 0
        },
        selectedRange: {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.contrastText,
            borderRadius: 0
        },
        selected: {
            outline: false,
        },
        'DayPicker-Day': {
            border: 30
        }
    };

    return (
        <div className="SelectedWeekExample">
            <DayPickerInput               
                style={{ width: "100%" }}
                component={
                    React.forwardRef((props, ref) => <TextField label={title} {...props}
                        className={classes.dayPickerInput}
                        value={`Year: ${moment(selectedDays[0]).isoWeekYear()
                            }, Week: ${moment(selectedDays[0]).isoWeek()
                            } `}
                        variant="outlined" />)
                }
                dayPickerProps={{
                    modifiers: modifiers,
                    modifiersStyles: modifiersStyles,
                    firstDayOfWeek: 1,
                    selectedDays: selectedDays,
                    showOutsideDays: true,
                    showWeekNumbers:true,
                    onDayClick: handleDayChange,
                    onDayMouseEnter: handleDayEnter,
                    onDayMouseLeave: handleDayLeave,
                    onWeekClick: handleWeekClick
                }}
            />
            <style>{`
    .SelectedWeekExample{
    outline: none;
    width: 100%;
    }
            

            .SelectedWeekExample.DayPicker - Month {
    border - collapse: separate;
    outline: none;
}
            .SelectedWeekExample.DayPicker - WeekNumber {
    outline: none;
    font - family: ${theme.typography.fontFamily};
    font - size: ${theme.typography.fontSize};
    font - weight: ${theme.typography.fontWeightBold};
}
            .SelectedWeekExample.DayPicker - Day {
    outline: none;
    border: 2px solid transparent;
    font - family: ${theme.typography.fontFamily};
    font - size: ${theme.typography.fontSize};
    font - weight: ${theme.typography.fontWeightMedium};

}
`}</style>
        </div>

    )
}