import { CircularProgress } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import React, { useState } from 'react';
import { useAppState } from '../context/zustand';
import { myAxios } from '../utils/request';
import {Copyright} from "../components/Copyright";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        height: "100%",
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function SignIn() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [check, setCheck] = useState(false);
    const classes = useStyles();
    const setAlert = useAppState(s => s.setAlert)
    const login = useAppState(s => s.login)

    const [formError, setFormError] = useState({username:"", password:""})


    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        let conf = {
            method: 'post',
            url: '/api/token/',
            data: { username, password }
        }

        myAxios(conf)
            .then(res => {
                setCheck(true);
                setFormError({username:"", password:""});

                setTimeout(() => {
                    login(res.data)
                    setAlert({
                        severity: 'success',
                        message: "Logged in"
                    })
                   
                    setCheck(false);
                    setLoading(false);
                }, 1000)


            })
            .catch((err) => {

                setFormError({
                    username: err.data?.username || false,
                    password: err.data?.password || false
                })
                setLoading(false);
            })

    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
        </Typography>
                <form className={classes.form}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Username"
                        name="username"
                        autoComplete="username"
                        autoFocus
                        value={username}
                        error={formError.username}
                        helperText={formError.username}
                        onChange={(e) => { setUsername(e.target.value) }}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        error={formError.password}
                        helperText={formError.password}
                        onChange={(e) => { setPassword(e.target.value) }}
                    />
                    <Box height={40}>
                        {loading ?

                            (check ?
                                <CheckCircleOutlineRoundedIcon fontSize="large" color="primary" className={classes.submit} />
                                :
                                <CircularProgress fontSize="large" color="primary" className={classes.submit} />
                            )

                            :
                            <Button
                                disableElevation
                                type="submit"
                                fullWidth
                                color="primary"
                                variant="contained"
                                className={classes.submit}
                                onClick={handleSubmit}
                            >
                                Sign In
                    </Button>

                        }
                    </Box>

                </form>
            </div>
            <Box mt={8} marginX={12}>
                <Copyright/>
            </Box>
        </Container>
    );
}