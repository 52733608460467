import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import React from "react";

export function Copyright() {
    return (
        <>
            <Typography variant="body2" color="textSecondary" align="center">
                {`PODCAST TRUE DATA v ${window?.env?.FRONTEND_VERSION || 'X.X.X'} `}
                {`© ${new Date().getFullYear()} `}
                <Link color="inherit" href={"https://buttonmedia.eu/"}>
                    Button Systems, s.r.o.
                </Link>
            </Typography>
        </>
    );
}