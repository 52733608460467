import { useState, useEffect } from "react";
import { myAxios } from "../utils/request";

export const usePlatformCoef = () => {
  const [platformKoef, setPlatformKoef] = useState({});

  useEffect(() => {
    getCoeficients();
    // eslint-disable-next-line
  }, []);

  const getCoeficients = () => {
    let conf = {
      method: "get",
      url: `api/platforms/`,
    };

    myAxios(conf)
      .then((res) => {
        let obj = res.data.results.reduce((acc, item) => {
          acc[item.name] = item.coefficient;
          return acc;
        }, {});
        setPlatformKoef(obj);
      })
      .catch((err) => {});
  };

  return platformKoef;
};
